// ======================================================
// Base :: Global
// ======================================================

body {
  position: relative;
  height: 100%;
  font-size: $font-size-base;
  font-family: $font-family-base;

  &.has-no-background {
    .page {
      background: transparent;
      padding: 0;
    }
  }

  &.dark-theme-active {
    .ant-layout-sider-children,
    .menu,
    .switch-theme {
      background: $brand-background;
      color: $white;
    }

    .menu {
      .ant-menu-sub {
        &.ant-menu {
          background: $brand-background;
        }
      }

      .ant-menu-item {
        > a {
          color: $white;

          @include on-event() {
            color: $brand-color;
          }
        }
      }

      .ant-menu-item-selected {
        pointer-events: none;
        background-color: rgba($brand-color, 0.15) !important;

        &::after {
          border-right: 3px solid $brand-color;
        }

        > a {
          color: $brand-color;
        }
      }
    }
  }

  &.is-sidebar-mobile-active {
    @include breakpoint-down(phone) {
      overflow: hidden;
    }
  }
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

// ======================================================
// Layout :: Header
// ======================================================

.header {
  @include position(fixed, 0, 0, null, null);
  @include flexbox-centering-vertical;
  @include transition;
  z-index: z(header);
  justify-content: space-between;
  width: calc(100% - #{$sidebar-width});
  height: $header-height;
  padding: 0;
  background: $header-background;
  box-shadow: 4px 4px 40px 0 rgba($black, 0.05);

  @include breakpoint-down(phone) {
    width: 100%;
  }

  @media print {
    display: none;
  }

  @include breakpoint-down(extra-smalldesktop) {
    justify-content: flex-end;
  }

  @include breakpoint-down(phone) {
    justify-content: space-between;
  }

  &--expanded {
    width: calc(100% - #{$sidebar-width-collapsed});

    @include breakpoint-down(phone) {
      width: 100%;
    }
  }

  &__icon-trigger {
    @include flexbox-centering;
    @include square(72px);
    @include transition;
    cursor: pointer;

    @include breakpoint-down(extra-smalldesktop) {
      display: none;
    }

    @include breakpoint-down(phone) {
      display: flex;
      width: 64px;
    }

    &:hover {
      color: $brand-color;
      background: $section-background-hover;
    }
  }

  &__logo {
    display: none;
    width: 200px;
    height: 100%;

    @include breakpoint-down(phone) {
      display: block;
    }

    @include breakpoint-down(smallphone) {
      width: 180px;
    }

    @include breakpoint-down(extra-smallphone) {
      width: 150px;
    }
  }

  &__user-interaction {
    @include flexbox-centering-vertical;
    height: $header-height;
  }

  &__user-feedback {
    @include transition;
    display: flex;
    height: 100%;
    padding: 0 24px;

    &:hover {
      background: $section-background-hover;
    }
  }
}

.user-feedback {
  @include breakpoint-down(extra-smalldesktop) {
    display: none;
  }

  &__button {
    @include flexbox-centering;
    width: 100%;
    height: 32px;
    margin: auto;
    padding: 6px 15px;
    border-radius: $border-radius-hack;
    background: $brand-color;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}

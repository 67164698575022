// ======================================================
// Components :: Navigation
// ======================================================

.menu {
  @include transition;
  height: calc(100% - 144px);
  padding: 24px 0;
  border-right: 0;
  user-select: none;

  @include breakpoint-down(phone) {
    height: 100%;
    margin: -4px 0 0;
    padding: 0;
  }

  .ant-menu-inline {
    user-select: none;
  }

  .ant-menu-item {
    > a {
      @include on-event() {
        color: $brand-color;
      }
    }
  }

  .ant-menu-submenu-title {
    @include on-event() {
      color: $brand-color;

      > .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: linear-gradient(to right, $brand-color, $brand-color);
        }
      }
    }
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    color: $brand-color !important;

    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        background: linear-gradient(to right, $brand-color, $brand-color);
      }
    }
  }

  .ant-menu-item-selected {
    pointer-events: none;
    background-color: rgba($brand-color, 0.15) !important;

    &::after {
      border-right: 3px solid $brand-color;
    }

    > a {
      color: $brand-color;
    }
  }
}

// Ant menu collapsed popup
.ant-menu {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item {
      > a {
        @include on-event() {
          color: $brand-color;
        }
      }
    }

    .ant-menu-item-selected {
      pointer-events: none;
      background-color: rgba($brand-color, 0.15) !important;

      > a {
        color: $brand-color;
      }
    }
  }
}

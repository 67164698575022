// ======================================================
// Components :: Dropdowns
// ======================================================

.dropdown-user {
  @include flexbox-centering-vertical;
  @include transition;
  cursor: pointer;
  user-select: none;
  height: 100%;
  padding: 0 $content-padding;

  @include breakpoint-down(phone) {
    padding: 0 20px;
  }

  &:hover {
    background: $section-background-hover;
  }

  &__greeting {
    @include breakpoint-down(phone) {
      display: none;
    }
  }

  &__username {
    @include truncate;
    max-width: 200px;
    padding: 0 10px 0 5px;

    @include breakpoint-down(phone) {
      display: none;
    }
  }

  &__image {
    @include circle(32px);
    min-width: 32px;
  }

  &__menu-item {
    @include flexbox-centering-vertical;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }

  &__menu-text {
    padding-left: 5px;
  }
}

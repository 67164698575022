// ======================================================
// Layout :: Sidebar
// ======================================================

.sidebar {
  @include position(fixed, 0, null, 0, 0);
  z-index: z(sidebar);
  box-shadow: 0 0 28px 0 rgba(24, 144, 255, 0.1);

  @include breakpoint-down(phone) {
    z-index: z(sidebar-mobile);
  }

  @media print {
    display: none;
  }

  &--collapsed {
    @include breakpoint-down(phone) {
      flex: 0 0 0 !important;
      width: 0 !important;
      min-width: 0 !important;
      max-width: 0 !important;
    }

    .switch-theme {
      * {
        display: none;
      }
    }

    .auth-logo {
      &__link {
        width: 90%;
      }
    }
  }

  &__icon-trigger {
    display: none;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint-down(phone) {
      display: flex;
    }
  }

  .auth-logo {
    @include breakpoint-down(phone) {
      display: none;
    }
  }

  .ant-layout-sider-children {
    background: $sidebar-background;
  }

  .ant-menu-inline-collapsed {
    @include breakpoint-down(phone) {
      width: 0;
    }

    > .ant-menu-item {
      @include breakpoint-down(phone) {
        padding: 0 !important;
      }
    }
  }
}

.auth-logo {
  @include flexbox-centering;
  @include transition;
  user-select: none;
  height: $header-height;
  margin: 0;

  &__link {
    width: 50%;
  }
}

.switch-theme {
  @include flexbox-centering-vertical;
  justify-content: space-between;
  height: $header-height;
  padding: 0 24px;

  @include breakpoint-down(phone) {
    display: none;
  }

  &__title {
    @include flexbox-centering;
  }

  &__title-text {
    padding-left: 10px;
    line-height: 1;
  }
}

// ======================================================
// Components :: Breadcrumb
// ======================================================

.breadcrumb {
  position: relative;
  z-index: z(breadcrumb);
  height: $breadcrumb-height;

  @media print {
    display: none;
  }

  @include breakpoint-down(phone) {
    height: 30px;
  }

  > span {
    &:last-child {
      .ant-breadcrumb-separator {
        display: none;
      }
    }
  }
}

.ant-breadcrumb-link {
  text-transform: capitalize;
}

// ======================================================
// Layout :: Content
// ======================================================

.wrapper {
  display: flex;
  background: #f0f2f5;

  &__backdrop {
    @media print {
      display: none;
    }
    @include breakpoint-down(phone) {
      @include position(fixed, 0, 0, 0, 0);
      z-index: z(wrapper-backdrop);
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.main {
  @include transition;
  flex: 1 1 auto;
  min-height: 100vh;
  padding-left: $sidebar-width;

  @include breakpoint-down(phone) {
    padding-left: 0;
  }

  @media print {
    padding-left: 0;
  }

  &--expanded {
    padding-left: $sidebar-width-collapsed;

    @include breakpoint-down(phone) {
      padding-left: 0;
    }
  }

  .project-tag {
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    max-width: 150px;
    text-overflow: ellipsis;
  }
}

.main-inner {
  padding: $header-height 0 0;

  @media print {
    padding: 0;
  }
}

.content {
  position: relative;
  min-height: calc(100vh - (#{$header-height} + #{$footer-height}));
  padding: $content-padding;

  @media print {
    padding: 0;
  }

  @include breakpoint-down(phone) {
    padding: 20px;
  }

  @include breakpoint-down(smallphone) {
    padding: 15px;
  }
}

.page {
  position: relative;
  min-height: calc(
    100vh -
      (#{$header-height} + #{$footer-height} + (2 * #{$content-padding}) + #{$breadcrumb-height})
  );
  background: $white;
  padding: $content-padding;
  box-shadow: 4px 4px 20px 0 rgba($black, 0.01);

  .ant-page-header {
    padding: 0;

    .ant-page-header-title-view-extra {
      top: 0;
      right: 0;
    }
  }

  .show-on-printer {
    display: none;
  }

  @media print {
    .show-on-printer {
      display: block;
    }
    .hide-on-printer {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .page {
    padding: #{$content-padding / 2};
    min-height: calc(100vh - 160px);
  }
}

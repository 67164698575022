// ======================================================
// Components :: Chart
// ======================================================

.chart {
  position: relative;
  user-select: none;

  &.is-empty {
    position: static;
  }

  &__empty {
    @include position(absolute, 50%, null, null, 50%);
    z-index: z(base);
    transform: translate(-50%, -50%);
  }

  &__shape {
    @include flexbox-centering;
    flex: 1;

    @include breakpoint-down(phone) {
      display: none;
    }
  }

  &__note {
    @include position(absolute, 0, 0, null, null);
    display: flex;
    flex-direction: column;
    min-width: 25%;
    max-width: 25%;

    @include breakpoint-down(phone) {
      position: static;
      min-width: 100%;
      max-width: none;
    }
  }

  &__note-item {
    @include flexbox-centering-vertical;
    justify-content: flex-end;
    width: 100%;

    @include breakpoint-down(phone) {
      justify-content: flex-start;
    }

    &:not(:last-child) {
      margin: 0 0 12px;
    }

    &::before {
      display: none;
      order: 3;
      content: attr(data-count);

      @include breakpoint-down(phone) {
        display: inline-block;
      }
    }
  }

  &__note-tag {
    @include truncate;
    cursor: pointer;
    max-width: 100%;

    @include breakpoint-down(phone) {
      order: 1;
      margin: 0 5px;
    }
  }

  svg {
    > g {
      > path {
        cursor: pointer;
      }
    }
  }
}

// ======================================================
// Layout :: Footer
// ======================================================

.footer {
  @include flexbox-centering;
  min-height: $footer-height;

  @media print {
    display: none;
  }

  &--auth {
    background: $footer-background;
  }

  &--nonauth {
    @include position(absolute, null, 0, 0, 0);
  }
}

// ======================================================
// Pages :: Error
// ======================================================

.error-page {
  @include position(absolute, 0, 0, 0, 0);
  @include flexbox-centering;
  flex-direction: column;
  padding: 12px;
  text-align: center;
  color: $grey;

  &.with-background {
    background: $white;
  }

  p {
    font-weight: $font-weight-medium;
  }

  &__subtitle {
    white-space: pre-line;
    &::first-line {
      font-size: 50px !important;
      line-height: 100px !important;
    }
  }

  &__title,
  &__subtitle::first-line {
    margin: 0;
    font-size: 200px;
    line-height: 200px;
    color: $brand-color;

    @include breakpoint-down(tablet) {
      font-size: 150px;
      line-height: 150px;
    }

    @include breakpoint-down(phone) {
      font-size: 100px;
      line-height: 100px;
    }

    @include breakpoint-down(smallphone) {
      font-size: 80px;
      line-height: 80px;
    }
  }

  &__link {
    color: $brand-color;

    @include on-event() {
      color: $brand-color;
    }
  }
}

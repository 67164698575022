// ======================================================
// Components :: Filter
// ======================================================

.filter-select {
  text-transform: uppercase;

  .ant-select-selection__placeholder {
    text-transform: capitalize;
  }
}

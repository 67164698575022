// ======================================================
// Components :: Modals
// ======================================================

.modal-action {
  textarea {
    &.ant-input {
      resize: none;
      box-shadow: none;
      min-height: 80px;

      @include breakpoint-down(phone) {
        min-height: 70px;
      }
    }
  }

  .ant-modal-close {
    display: none;
  }

  &__button-group {
    @include flexbox-centering-vertical;
    margin: $content-margin 0 0;
    justify-content: flex-end;

    > span {
      pointer-events: none;
    }
  }

  &__button {
    min-width: 80px;

    > button {
      width: 100%;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.ant-modal-mask {
  background-color: rgba($black, 0.4);
}

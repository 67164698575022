// ======================================================
// Components :: Attachments
// ======================================================

.attachments {
  user-select: none;

  &--small {
    width: 50px !important;
    height: 50px !important;
    padding: 4px !important;
  }
}

// ======================================================
// Components :: Icons
// ======================================================

.trigger {
  @include transition;
  font-size: $font-size-large;

  &:hover {
    color: $brand-color;
  }
}

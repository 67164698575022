// ======================================================
// Pages :: Request
// ======================================================

.request-action {
  @include flexbox-centering-vertical;

  &.is-success {
    color: $green;

    @include on-event() {
      color: $green;
    }
  }

  &.is-danger {
    color: $red;

    @include on-event() {
      color: $red;
    }
  }
}

.request-title {
  @include line-clamp;
  max-width: 600px;

  @include breakpoint-down(small-desktop) {
    max-width: 300px;
  }

  @include breakpoint-down(extra-smalldesktop) {
    max-width: none;
  }
}

.request-by {
  @include flexbox-centering-vertical;
  text-transform: capitalize;

  &__image {
    margin-right: 8px;
    min-width: 32px;
  }
}

.request-action-button {
  border: 0;
}

.request-page {
  .hide-on-mobile {
    @include breakpoint-down(phone) {
      display: none;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    .ant-page-header-back,
    .ant-page-header-title-view {
      @include breakpoint-down(extra-smalldesktop) {
        margin-bottom: auto;
      }
    }

    .ant-page-header-back {
      min-width: 44px;
      @media print {
        display: none;
      }
    }

    .ant-page-header-title-view {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-tags {
        .ant-input-affix-wrapper {
          display: inline;
          input {
            width: fit-content;
            display: inline-block;
          }
          .ant-input-suffix {
            right: 0 !important;
          }
        }
        @include breakpoint-down(phone) {
          display: flex;
          flex-direction: column;
          .ant-tag {
            display: inline-block;
            margin-bottom: 3px;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: fit-content;
            flex: 1;
          }
        }
      }
    }

    .ant-page-header-title-view-title {
      line-height: 24px;

      @include breakpoint-down(phone) {
        font-size: 14px;
      }
    }

    .ant-page-header-content-view {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: -5px;
      margin-top: -5px;
      margin-bottom: -5px;
      padding: 0;
      @include breakpoint-down(phone) {
        min-width: 40%;
        max-width: 120px;
      }

      @media print {
        display: none;
      }

      > * {
        margin: 5px;

        span {
          @include breakpoint-down(phone) {
            display: none;
          }
        }
      }
    }
  }
}

.request-form,
.request-detail {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    margin: 0 -12px;

    &.is-submit {
      margin: 0;
      justify-content: center;
    }
  }

  &__item-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 12px;
  }

  &__item-cell {
    @include transition;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 16px;
    padding: 8px 12px;
    border: 1px solid $border-color;
    border-radius: 4px;

    > div {
      flex: 1;
      margin: 0 !important;
    }

    .ant-form-item-control {
      line-height: 24px;
    }
  }

  &__item-note {
    min-height: 104px !important;
    max-height: 104px;
    resize: none;
  }

  &__item-submit {
    margin: 0 6px;
    min-width: 100px;
  }

  &__item-error {
    margin: 0 0 16px;
  }

  &__cash {
    display: flex;
  }

  &__cash-icon {
    margin: 0 5px;
  }

  &__cash-tag {
    margin: 0;
  }

  &__add-row {
    user-select: none;
    @include flexbox-centering-vertical;
    justify-content: flex-end;
    margin: -36px 0 12px;
  }

  .ant-empty {
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label {
    line-height: 1;
    margin: 0 0 8px;

    @include breakpoint-down(phone) {
      overflow: visible;
      padding: 0;
    }

    label {
      &::before {
        @include position(absolute, null, 0, null, null);
        z-index: z(base);
        margin: 0;
        font-size: 16px;

        @include breakpoint-down(smallphone) {
          right: -10px;
        }
      }

      &::after {
        content: '';
      }
    }
  }
}

.ant-modal-title {
  padding-right: 10px;
}

.request-total {
  @include flexbox-centering-vertical;
  justify-content: flex-end;
  margin: 12px 0 0;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
  }

  &__item-row {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: left;

    @include breakpoint-down(phone) {
      flex-direction: column;
    }
  }

  &__item-card {
    &:not(:first-child) {
      margin-left: 12px;

      @include breakpoint-down(phone) {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    .ant-card-body {
      padding: 10px;
    }

    .ant-statistic {
      line-height: 1;
    }
  }

  &__title {
    margin: 10px 0 0;
    font-weight: 500;
    line-height: 1;
  }

  .item-card {
    &__title {
      .ant-statistic-content-value {
        color: rgba($black, 0.65);
      }

      &.is-highlight {
        .ant-statistic-content-value {
          color: $green;
        }
      }
    }
  }

  .ant-statistic-title {
    @include breakpoint-down(phone) {
      font-size: 13px;
    }
  }

  .ant-statistic-content-value {
    font-size: 20px;

    @include breakpoint-down(phone) {
      font-size: 18px;
    }
  }
}

.detail-list {
  $this: &;

  &__item {
    display: flex;

    @include breakpoint-down(phone) {
      flex-direction: column;
    }

    &.is-variant {
      flex-direction: column;

      #{$this}__item-title {
        margin: 0 0 5px;
      }

      #{$this}__item-content {
        margin: 0;
      }
    }

    &.row-fixed {
      @include breakpoint-down(phone) {
        flex-direction: row;
      }
    }

    &.has-more-reviewers,
    &.has-more-travellers,
    &.has-more-watchers {
      @include transition;
      overflow: hidden;
      height: 42px;

      #{$this}__item-content {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        background: #f0f2f5;

        .user-avatar {
          margin-top: 5px;
          margin-left: 5px;

          > span:first-child {
            margin-top: 0;
          }
        }
      }

      &.expanded {
        overflow: visible;
        height: auto;

        #{$this}__item-content {
          padding: 5px;
        }
      }
    }

    &:not(:last-child) {
      margin: 0 0 12px;
    }
  }

  &__item-title {
    position: relative;
    display: inline-block;
    word-break: break-all;
    font-weight: $font-weight-semi-bold;

    &.no-colon {
      &::after {
        content: '';
      }
    }

    &::after {
      content: ':';
      position: absolute;
      z-index: z(base);
      right: 0;

      @include breakpoint-down(phone) {
        display: none;
      }
    }
  }

  &__item-content {
    flex: 1;
    margin-left: 10px;
    word-break: break-all;

    @include breakpoint-down(phone) {
      margin: 0;
    }
  }

  .expand-participant {
    position: absolute;
    z-index: z(base);
    top: 20px;
    right: 0;
    transform: translateY(-50%);

    > span {
      @include breakpoint-down(phone) {
        display: none;
      }
    }
  }
}

.has-warning {
  color: $warning-color;
  background: $warning-background-color;
  border-color: $warning-border-color;
}

.request-list__filter {
  margin-bottom: 15px !important;
}

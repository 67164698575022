// ======================================================
// Pages :: Loading
// ======================================================

.loader {
  @include position(fixed, 0, 0, 0, 0);
  @include flexbox-centering;
  z-index: z(loader);
  background: $white;

  &__warpper {
    @include square(100px);
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__inner {
    @include circle(40px);
    z-index: z(loader-inner);
    margin: 0 auto;
    text-indent: -12345px;
    border-top: 1px solid rgba($brand-color, 0.08);
    border-right: 1px solid rgba($brand-color, 0.08);
    border-bottom: 1px solid rgba($brand-color, 0.08);
    border-left: 1px solid rgba($brand-color, 0.7);
    animation: spinner 600ms infinite linear;
  }

  &__text {
    @include size(100px, 20px);
    color: $brand-color;
    font-size: $font-size-small;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
  }
}

.loader-single-page {
  @include position(absolute, 0, 0, 0, 0);
  @include flexbox-centering;
  z-index: z(loader-single-page);

  &.dark {
    background-color: rgba($black, 0.05);
  }
}

// Change nprogress colors
#nprogress {
  .bar {
    background: $brand-color;
  }

  .spinner-icon {
    border-top-color: $brand-color;
    border-left-color: $brand-color;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// ======================================================
// Pages :: Request Form
// ======================================================

.request-form {
  &__item {
    @include breakpoint-down(tablet) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__item-submit {
    @include breakpoint-down(tablet) {
      margin: 0;
    }

    &:not(:last-child) {
      @include breakpoint-down(tablet) {
        margin: 0 0 10px;
      }
    }
  }

  &__item-column {
    @include breakpoint-down(tablet) {
      margin: 0;
    }
  }

  &__item-inline {
    @include transition;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 16px;

    > div {
      flex: 1;
      margin-bottom: 0 !important;
    }

    > div:not(:first-child) {
      margin-left: 8px;
    }

    .ant-form-item-control {
      line-height: 24px;
    }
  }

  &__item-cell {
    @include breakpoint-down(tablet) {
      overflow: auto;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px;
    }

    > div {
      flex: 1;
      margin: 0 !important;

      @include breakpoint-down(tablet) {
        width: 100%;
      }

      &:last-child {
        @include breakpoint-down(tablet) {
          margin: 10px 0 0 !important;
        }
      }
    }

    .ant-form-item-control {
      line-height: 24px;
    }
  }

  &__table {
    @include breakpoint-down(extra-smalldesktop) {
      @include table-response-mobile($td-padding-left: 50%, $hasCustomTr: true) {
        &:nth-child(odd) {
          background-color: $grey-light;
        }
      }
    }

    .ant-table-body {
      margin: 0 !important;
    }
  }
}

.preview-pdf {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100px;
  background: rgba($black, 0.03);
  border-radius: 4px;

  &:hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }

  &::before {
    content: 'View File';
    @include position(absolute, 0, 0, 0, 0);
    @include flexbox-centering;
    @include transition;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    font-size: 200%;
    color: $white;
    background: rgba($black, 0.2);
  }

  &__icon {
    margin: auto;
    font-size: 300%;
  }
}

// ======================================================
// Pages :: Home
// ======================================================

.home {
  &__statistic {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;

    @include breakpoint-down(phone) {
      margin: -10px;
    }

    @include breakpoint-down(smallphone) {
      margin: 0;
    }
  }

  &__statistic-item {
    @include flexbox-centering;
    @include transition;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: calc(25% - 24px);
    height: 140px;
    margin: 12px;
    padding: 12px;
    background: $white;

    @include breakpoint-down(extra-smalldesktop) {
      width: calc(50% - 24px);
    }

    @include breakpoint-down(phone) {
      width: calc(50% - 20px);
      margin: 10px;
      padding: 10px;
    }

    @include breakpoint-down(smallphone) {
      justify-content: flex-start;
      width: 100%;
      height: 100px;
      margin: 0 0 12px;
      padding-left: 30px;
    }

    &.is-loading {
      pointer-events: none;
    }

    &:hover {
      box-shadow: 4px 4px 40px rgba($black, 0.05);
    }

    &:last-child {
      @include breakpoint-down(smallphone) {
        margin: 0;
      }
    }
  }

  &__statistic-content-group {
    @include flexbox-centering-vertical;

    @include breakpoint-down(small-desktop) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    @include breakpoint-down(small-desktop) {
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
    }

    @include breakpoint-down(phone) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    @include breakpoint-down(smallphone) {
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &__statistic-icon {
    font-size: 54px;
    color: $brand-color;

    @include breakpoint-down(small-desktop) {
      font-size: 44px;
    }
  }

  &__statistic-content {
    margin-left: 12px;

    @include breakpoint-down(small-desktop) {
      margin-top: 12px;
      margin-left: 0;
    }

    @include breakpoint-down(small-desktop) {
      margin-top: 0;
      margin-left: 12px;
    }

    @include breakpoint-down(phone) {
      margin-top: 12px;
      margin-left: 0;
    }

    @include breakpoint-down(smallphone) {
      margin-top: 0;
      margin-left: 12px;
    }
  }

  &__statistic-title {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;

    @include breakpoint-down(small-desktop) {
      font-size: 14px;
      line-height: 14px;
    }

    @include breakpoint-down(extra-smalldesktop) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__statistic-subtitle {
    font-size: 24px;
    line-height: 24px;
  }

  &__statistic-amount {
    text-align: left;
    font-size: 18px;
    line-height: 18px;

    @include breakpoint-down(small-desktop) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__statistic-currency {
    display: inline-block;
    min-width: 40px;
    max-width: 40px;
    text-transform: uppercase;

    @include breakpoint-down(small-desktop) {
      min-width: 36px;
      max-width: 36px;
    }
  }

  &__detail {
    display: flex;
    flex-wrap: wrap;
    margin: 12px -12px 0;

    @include breakpoint-down(phone) {
      margin: 12px 0 0;
    }

    @include breakpoint-down(smallphone) {
      margin: 0;
    }
  }

  &__detail-item {
    position: relative;
    width: calc(50% - 24px);
    min-height: calc(
      100vh -
        (
          (
              #{$header-height} + #{$footer-height} + (2 * #{$content-padding}) + #{$breadcrumb-height}
            ) + 164px + 12px
        )
    );
    margin: 12px 12px 0;
    padding: 24px;
    background: $white;

    @include breakpoint-down(extra-smalldesktop) {
      width: calc(100% - 24px);
      min-height: 300px;
    }

    @include breakpoint-down(phone) {
      width: 100%;
      margin: 12px 0 0;
      padding: 20px;
    }

    @include breakpoint-down(smallphone) {
      padding: 15px;
    }

    &:first-child {
      @include breakpoint-down(phone) {
        order: 2;
      }
    }

    &:last-child {
      @include breakpoint-down(phone) {
        order: 1;
      }
    }
  }

  &__detail-title {
    margin: 0 0 24px;
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
    text-transform: capitalize;

    @include breakpoint-down(phone) {
      margin: 0 0 20px;
    }

    @include breakpoint-down(smallphone) {
      margin: 0 0 16px;
    }
  }

  &__detail-icon {
    padding-right: 10px;
  }

  &__detail-content {
    display: flex;
    height: 100%;
    min-height: calc(
      100vh -
        (
          (
              #{$header-height} + #{$footer-height} + (2 * #{$content-padding}) + #{$breadcrumb-height}
            ) + 164px + 12px + (2 * #{$content-padding}) + (2 * #{$content-padding})
        )
    );

    @include breakpoint-down(small-desktop) {
      height: 100%;
    }

    @include breakpoint-down(extra-smalldesktop) {
      min-height: 0;
    }
  }

  &__filter {
    @include position(absolute, -35px, 0, null, null);
    @include flexbox-centering-vertical;
    justify-content: flex-end;
    flex-wrap: wrap;
    z-index: z(dashboard-filter);

    @media print {
      display: none;
    }

    @include breakpoint-down(phone) {
      position: static;
      margin: 0 0 16px;
    }

    @include breakpoint-down(smallphone) {
      margin: 0 0 12px;
    }
  }

  &__filter-item {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &.is-select {
      min-width: 200px;

      @include breakpoint-down(phone) {
        min-width: 150px;
      }

      @include breakpoint-down(smallphone) {
        margin-right: 0;
      }

      > div {
        width: 100%;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      @include breakpoint-down(smallphone) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      button {
        @include breakpoint-down(smallphone) {
          width: 100%;
        }
      }
    }

    &:nth-child(3) {
      @include breakpoint-down(smallphone) {
        margin: 10px 0;
      }
    }

    &:nth-child(4) {
      @include breakpoint-down(smallphone) {
        margin: 0 0 10px;
      }
    }
  }
}

.latest-request {
  &__empty {
    @include position(absolute, 50%, null, null, 50%);
    z-index: z(base);
    transform: translate(-50%, -50%);
  }

  &__item {
    @include transition;
    display: inline-block;
    width: 100%;
    min-height: 75px;
    padding: 10px 12px;

    @include breakpoint-down(smallphone) {
      padding-left: 0;
    }

    .ant-list-item-tags {
      flex-direction: column;
      display: flex;
      align-items: flex-end;

      .ant-tag {
        margin: unset;
        margin-bottom: 4px;
      }

      @include breakpoint-down(smallphone) {
        padding-left: 56px;
        flex-direction: row;
        .ant-tag {
          margin-right: 4px;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid;
      border-color: #f4f4f4;
    }

    &:hover {
      background: #e6f7ff;

      .ant-list-item-meta-title,
      .ant-list-item-meta-description {
        &::before,
        &::after {
          background: #e6f7ff;
        }
      }
    }

    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      &::before,
      &::after {
        @include transition;
      }
    }
  }

  &__tag {
    cursor: pointer;
    margin-right: 0;
    margin-left: 24px;

    @include breakpoint-down(smallphone) {
      margin-top: 10px;
      margin-left: 50px;
    }
  }

  .ant-list-item {
    position: relative;
    padding: 0;

    @include breakpoint-down(smallphone) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-meta-avatar {
    position: absolute;
    z-index: z(base);
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    @include breakpoint-down(smallphone) {
      margin-right: 10px;
    }
  }

  .ant-list-item-meta-content {
    word-break: break-all;
    padding-left: 56px;
  }

  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    @include line-clamp;

    @include breakpoint-down(smallphone) {
      @include line-clamp($line-clamp: 1);
    }
  }
}

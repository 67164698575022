// ======================================================
// Components :: Print
// ======================================================

@media print {
  @page {
    margin: 0;
  }

  .page-break-after {
    page-break-after: always;
  }
}

// ======================================================
// Components :: Back top
// ======================================================

.back-top {
  right: 50px;

  @media print {
    display: none;
  }

  @include breakpoint-down(phone) {
    right: 30px;
    bottom: 30px;
  }

  @include breakpoint-down(smallphone) {
    right: 15px;
    bottom: 15px;
  }

  .ant-back-top-content {
    background-color: rgba($brand-color, 0.5);
  }
}

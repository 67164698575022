// ======================================================
// Abstracts :: Mixins
// ======================================================

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flexbox-centering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexbox-centering-vertical() {
  display: flex;
  align-items: center;
}

@mixin flexbox-centering-horizontal() {
  display: flex;
  justify-content: center;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($dimension) {
  width: $dimension;
  height: $dimension;
}

@mixin circle($dimension) {
  width: $dimension;
  height: $dimension;
  border-radius: 50%;
}

@mixin transition($what: all, $time: 0.2s, $how: ease) {
  transition: $what $time $how;
}

@mixin list-unstyled() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin reset-button() {
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  box-shadow: none;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
}

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin truncate-multiline($line-height: 1.2em, $line-count: 1, $bg-color: $white) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: $line-height * $line-count;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

@mixin line-clamp($line-clamp: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin breakpoint-down($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin table-response-mobile($td-padding-left: 50%, $hasCustomTr: false) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block !important;
  }

  thead {
    tr {
      @include position(absolute, -9999px, null, null, -9999px);
    }
  }

  tbody {
    @if $hasCustomTr {
      tr {
        @content;
      }
    }

    td {
      position: relative !important;
      padding-left: $td-padding-left !important;
      text-align: left !important;

      &::before {
        content: attr(data-title) !important;
        @include position(absolute, 50%, null, null, 10px);
        transform: translateY(-50%);
        padding-right: 10px !important;
        white-space: nowrap !important;
        font-weight: 500 !important;
      }
    }
  }
}

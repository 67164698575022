// ======================================================
// Pages :: Report
// ======================================================

.report {
  display: flex;
  height: 100%;

  @include breakpoint-down(tablet) {
    flex-direction: column;
  }

  &__item {
    min-height: calc(
      100vh -
        (#{$header-height} + #{$footer-height} + #{$breadcrumb-height} + (2 * #{$content-padding}))
    );
    background: $white;
    padding: 24px;

    @include breakpoint-down(tablet) {
      min-height: 300px;
    }

    @include breakpoint-down(phone) {
      padding: 20px;
    }

    @include breakpoint-down(smallphone) {
      padding: 15px;
    }
  }

  object {
    @include breakpoint-down(phone) {
      height: 100vh;
    }
  }
}

.report-document {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 12px;

  @include breakpoint-down(tablet) {
    width: 100%;
    margin: 0;
    order: 2;
  }
}

.report-filter {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 12px;

  @include breakpoint-down(tablet) {
    width: 100%;
    margin: 0 0 24px;
    order: 1;
  }

  @include breakpoint-down(phone) {
    margin: 0 0 20px;
  }

  @include breakpoint-down(smallphone) {
    margin: 0 0 15px;
  }

  &__item {
    &:not(:last-child) {
      margin: 0 0 16px;
    }
  }

  &__item-title {
    margin: 0 0 5px;
    font-weight: 500;
  }
}

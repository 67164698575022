// ======================================================
// Abstracts :: Variables
// ======================================================

$img-url: '../images';

$font-family-base: 'Roboto', sans-serif;

$white: #fff;
$black: #000;
$white-darker: #f9f9fc;
$orange: #ff6b00;
$grey-light: #eee;
$grey: #252f3d;

$green: #87d068;
$red: #f50;
$blue: #108ee9;

$font-size-small: 12px;
$font-size-base: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

$border-radius-base: 4px;
$border-radius-hack: 100px;

$border-color: $grey-light;
$brand-color: $orange;
$brand-background: $grey;

$z-indexes: (
  base: 1,
  under: -1,
  upper: 2,
  header: 1000,
  sidebar: 1000,
  sidebar-mobile: 2000,
  breadcrumb: 999,
  calendar-picker: 999,
  loader: 1001,
  loader-inner: 1002,
  loader-single-page: 999,
  dashboard-filter: 999,
  wrapper-backdrop: 1999,
);

$breakpoints: (
  'desktop': (
    max-width: 1199.98px,
  ),
  'small-desktop': (
    max-width: 1600px,
  ),
  'extra-smalldesktop': (
    max-width: 1200px,
  ),
  'tablet': (
    max-width: 991.98px,
  ),
  'phone': (
    max-width: 767.98px,
  ),
  'smallphone': (
    max-width: 575.98px,
  ),
  'extra-smallphone': (
    max-width: 320px,
  ),
);

$sidebar-width: 256px;
$sidebar-width-collapsed: 80px;
$sidebar-background: $white;

$header-height: 72px;
$header-background: $white;

$footer-height: 72px;
$footer-background: $white;

$breadcrumb-height: 40px;

$content-gutter: 24px;
$content-margin: $content-gutter;
$content-padding: $content-gutter;

$label-success: $green;
$label-danger: $red;

$section-background-hover: $white-darker;

// https://www.w3schools.com/bootstrap/bootstrap_alerts.asp
$success-color: #3c763d;
$success-background-color: #dff0d8;
$success-border-color: #d6e9c6;

$info-color: #31708f;
$info-background-color: #d9edf7;
$info-border-color: #bce8f1;

$warning-color: #8a6d3b;
$warning-background-color: #fcf8e3;
$warning-border-color: #faebcc;

$danger-color: #020000;
$danger-background-color: #f2dede;
$danger-border-color: #ebccd1;

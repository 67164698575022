// ======================================================
// Pages :: Request Reimburse
// ======================================================

.request-reimburse {
  .reimburse-date {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    .reimburse-date {
      width: 100%;
    }
  }
  &__add-row {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 8px;
  }

  &__table {
    @include breakpoint-down(extra-smalldesktop) {
      @include table-response-mobile($td-padding-left: 50%, $hasCustomTr: true) {
        &:not(:last-child) {
          border-bottom: 1px solid $orange;
        }
      }
    }

    .ant-table-body {
      margin: 0 !important;
    }

    .ant-table-row.warning > td {
      background-color: $warning-background-color !important;
    }

    .ant-upload {
      &.ant-upload-select-picture-card {
        width: 48px;
        height: 48px;
        max-height: 48px;

        > .ant-upload {
          padding: 4px;
          font-size: 10px;
        }
      }
    }

    .ant-upload-list-picture-card {
      .ant-upload-list-item {
        width: 50px;
        height: 50px;
        padding: 4px;

        &:hover {
          .ant-upload-list-item-info {
            &::before {
              left: 0;
            }
          }
        }
      }
    }

    .ant-upload-list-item-actions {
      .anticon-eye-o {
        width: 14px;
        font-size: 14px;
      }

      .anticon-delete {
        font-size: 12px;
        margin: 0 2px;
      }
    }
  }
}

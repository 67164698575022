// ======================================================
// Base :: Helpers
// ======================================================

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.flexbox-centering {
  @include flexbox-centering;
}

.flexbox-centering-vertical {
  display: flex !important;
  align-items: center !important;
}

.align-items-center {
  align-items: center;
}

.word-break-all {
  word-break: break-all;
  white-space: pre-wrap;
}

.read-only {
  pointer-events: none;
}

.brand-color {
  color: $brand-color;
}

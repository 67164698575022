// ======================================================
// Pages :: Request Detail
// ======================================================

.request-detail {
  $this: &;

  &__item {
    margin: 0 -12px 12px;

    @include breakpoint-down(extra-smalldesktop) {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  &__item-column {
    @include breakpoint-down(extra-smalldesktop) {
      margin: 0 0 12px;
    }

    &.is-bordered {
      position: relative;
      padding: 24px 12px 12px;
      border: 1px solid $grey-light;

      &::after {
        content: attr(data-fieldset);
        @include position(absolute, -7px, null, null, 30px);
        z-index: z(base);
        min-width: 100px;
        background: $white;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        text-align: center;
      }
    }

    &[data-fieldset='Participants'] {
      .detail-list__item-title {
        align-self: center;
      }
    }
  }

  &__items {
    margin: 12px 0 24px;
  }

  &__items-icon {
    margin-right: 10px;
  }

  &__items-title,
  &__histories-title {
    margin: 0 0 12px;
    font-size: 15px;
  }

  &__statistic {
    @include flexbox-centering-vertical;
    justify-content: space-around;
    min-height: 104px;
    border: 1px solid $grey-light;
    border-radius: 3px;
    text-align: center;

    @include breakpoint-down(phone) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__statistic-item {
    padding: 12px 32px;
    border-radius: 3px;
    background: $white;
    text-align: left;

    @include breakpoint-down(small-desktop) {
      padding: 6px 12px;
    }
  }

  &__statistic-title {
    font-size: 14px;
    margin: 0 0 5px;
    text-transform: capitalize;
  }

  &__statistic-subtitle {
    margin: 0;
    color: rgba($black, 0.65);
    font-size: 16px;

    @include breakpoint-down(small-desktop) {
      font-size: 14px;
    }
  }

  &__statistic-icon {
    cursor: pointer;
    padding: 0 8px;
  }

  &__note {
    min-height: 104px;
    padding: 5px 10px;
    border: 1px solid $border-color;
    border-radius: 3px;
  }

  &__history-table {
    tbody > tr:nth-child(even) {
      background-color: $grey-light;
    }
  }

  .user-avatar {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;

    &__name {
      margin-left: 8px;
    }

    .ant-avatar {
      position: relative;
      margin-top: -4px;
      background-color: $orange;
    }
  }

  .attachment {
    cursor: pointer;

    &__pdf {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &__pdf-icon {
      margin: auto;
      font-size: 300%;
    }

    &__icon {
      margin-right: 5px;
    }
  }

  .ant-table-footer {
    text-align: right;
  }

  .ant-empty-normal {
    margin: 0;
  }

  .ant-page-header-back {
    @media print {
      display: none;
    }
  }

  @media print {
    thead {
      margin-top: 20px;
    }
    .dummy-footer {
      min-height: 100px;
    }
    i {
      display: none;
    }
    .ant-avatar {
      display: none;
    }
    .request-total {
      &__item {
        &-row {
          flex-direction: row;
        }
        &-card {
          flex: 1;
          margin-top: unset !important;
        }
      }
    }
    &__item {
      &-column {
        .user-avatar {
          margin: 0;
        }
        &[data-fieldset='Participants'] {
          .detail-list {
            column-count: 2 !important;
            .detail-list__item.watchers {
              display: none;
            }
          }
        }
        .ant-upload-list-picture-card .ant-upload-list-item {
          height: auto;
          max-height: 200px;
          width: 100px;
          padding: 0;
        }
        &:not(.request-purpose) {
          .detail-list {
            column-count: 3;
          }
        }
        .request-detail__statistic {
          column-count: 3;
          display: block !important;
          min-height: unset !important;
        }
      }
    }
    &__note {
      min-height: unset !important;
    }
    .ant-table-wrapper {
      border: 1px solid #eee;
      padding: 12px;
    }
    .user-avatar__name {
      margin-left: 0;
    }
    &__histories {
      //margin-top: 66px;
      .history-table {
        &__status {
          div {
            padding-left: 0;
            text-align: left;
            font-weight: bold;
            color: #000;
          }
        }
        tbody {
          td {
            padding-left: 25% !important;
          }
        }
      }
    }
    &__items {
      margin: 0;
      td {
        min-height: unset;
        padding: 5px;
        font-size: 12px;
      }
    }
  }
}

.item-table {
  &__attachments {
    @include truncate;
    display: block;
    max-width: 150px;
  }

  @media only screen {
    @include breakpoint-down(extra-smalldesktop) {
      @include table-response-mobile($td-padding-left: 50%, $hasCustomTr: true) {
        &:not(:last-child) {
          border-bottom: 1px solid $orange;
        }
      }
    }
  }
}

.history-table {
  &__status {
    > .ant-tag {
      min-width: 100px;
      text-align: center;
    }
  }

  &__action-icon {
    margin-right: 5px;
    color: $brand-color;
  }

  @include breakpoint-down(extra-smalldesktop) {
    @include table-response-mobile($td-padding-left: 40%);
  }
}

// ======================================================
// Pages :: Request List
// ======================================================

%button-new-request {
  color: $white;
  background: $brand-color;
  border-color: $brand-color;
}

.request-list {
  .ant-table-tbody {
    .ant-input-affix-wrapper {
      display: inline-block;
      max-width: 80%;
      input {
        width: inherit;
        display: inline-block;
      }
      .ant-input-suffix {
        right: 0 !important;
      }
    }
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;
    flex-direction: column;

    @include breakpoint-down(phone) {
      flex-direction: column;
      margin: 0 0 16px;
    }
  }

  &__filter-column {
    @include flexbox-centering-vertical;
    flex-wrap: wrap;
    flex: 1;
    margin: 6px -6px;

    @include breakpoint-down(extra-smalldesktop) {
      //margin: -6px;
    }

    @include breakpoint-down(phone) {
      margin: 0;
    }

    &.is-create {
      min-width: 140px;
      max-width: 140px;
      margin: 0;

      @include breakpoint-down(extra-smalldesktop) {
        justify-content: flex-end;
        align-items: flex-start;
        min-width: 30%;
        max-width: 30%;
        margin: 0;
      }

      @include breakpoint-down(phone) {
        justify-content: center;
        align-items: center;
        min-width: 100%;
        max-width: 100%;
        margin: 5px 0 0;
      }

      > button {
        width: 100%;

        @include breakpoint-down(extra-smalldesktop) {
          width: 140px;
        }

        @include breakpoint-down(phone) {
          width: 100%;
        }
      }
    }
  }

  &__filter.create-row {
    justify-content: flex-end !important;
    > button {
      max-width: 130px;
      min-width: 130px;
    }
  }

  #createTimeRangePicker {
    flex: 2;
  }

  &__filter-item {
    display: flex;
    flex: 1;
    margin: 0 6px;

    @include breakpoint-down(extra-smalldesktop) {
      width: calc(50% - 12px);
      min-width: calc(50% - 12px);
      max-width: calc(50% - 12px);
      margin: 6px;
    }

    @include breakpoint-down(phone) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 6px 0;
    }

    &.is-normal {
      //width: 130px;
      //max-width: 130px;
    }

    &.is-group-button {
      justify-content: space-evenly;
      > button {
        min-width: 125px;
        max-width: 130px;

        @include breakpoint-down(extra-smalldesktop) {
          flex: 1;
          min-width: 0;
          max-width: none;
        }

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }

  &__filter-create {
    @extend %button-new-request;

    @include on-event() {
      @extend %button-new-request;
    }
  }

  &__table {
    @include breakpoint-down(extra-smalldesktop) {
      @include table-response-mobile($td-padding-left: 40%, $hasCustomTr: true) {
        &:nth-child(odd) {
          background-color: $grey-light;
        }
      }
    }
  }

  .ant-calendar-picker-container {
    z-index: z(calendar-picker);
  }

  .ant-pagination {
    margin: 12px -4px -4px;

    > li {
      margin: 4px;

      &:first-child {
        @include breakpoint-down(phone) {
          display: none;
        }
      }
    }

    @include breakpoint-down(extra-smalldesktop) {
      float: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

// ======================================================
// Pages :: Login
// ======================================================

.auth-form {
  @include position(absolute, 50%, null, null, 50%);
  @include transition;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 100%;
  padding: 36px;
  box-shadow: 0 0 100px rgba($black, 0.08);

  @include breakpoint-down(smallphone) {
    width: 90%;
    padding: 24px;
  }

  &__button {
    @include flexbox-centering;
    @include transition;
    cursor: pointer;
    outline: none;
    width: 100%;
    margin: 15px 0 0;
    padding: 0;
    background: $brand-color;
    border: 1px solid $brand-color;
    border-radius: $border-radius-base;
    color: $white;
    font-size: $font-size-base;
    line-height: 34px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__button-text {
    padding-left: 5px;
  }
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
